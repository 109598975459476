// Variables
@import 'variables';

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// animate.css
@import '../../node_modules/animate.css';

//font awesome
@import "../../node_modules/font-awesome";

//material icons
@import "../../node_modules/material-design-iconic-font/scss/material-design-iconic-font";

// DataTables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";

//alertify
@import "../../node_modules/alertifyjs/build/css/alertify.css";

//fileupload
@import "../vendor/fileuploader-2.2/src/jquery.fileuploader";
@import "../vendor/fileuploader-2.2/examples/boxafter/css/jquery.fileuploader-theme-boxafter.css";

//slidebars
@import "../../node_modules/slidebars/dist/slidebars.css";

//codemirror
@import "../../node_modules/codemirror/lib/codemirror.css";
@import "../../node_modules/codemirror/theme/material-darker.css";
